
import { VueCookieNext } from 'vue-cookie-next';
import { $getCountry, $getNodeList, $existOem } from '@/assets/common/common.js';
import { lowVersionOfTheTip, loadAliyunCaptchaNew } from '@shining3d/xl-login';

// 设置给滚动条用的全局css变量
const setGlobalCssVar = () => { // 检测用户的操作系统
	const isMac = navigator.platform.toUpperCase().indexOf( 'MAC' ) >= 0;
	// 添加与操作系统相关的类名
	document.addEventListener( 'DOMContentLoaded', function() {
		if ( !isMac ) {
			document.documentElement.classList.add( 'not-mac' );
		}
	});
};

const init = () => {
	// 浏览器版本校验和提示-刘杰
	lowVersionOfTheTip();
	// 阿里云验证码初始化获取js-sdk - 江传华
	loadAliyunCaptchaNew();
	// 设置给滚动条用的全局css变量-杨涛
	setGlobalCssVar();
	// 获取节点列表
	$getNodeList();
	// 获取国家码
	$getCountry();
	// 判断已登录
};
// 获取当前地址OEM路径root
async function useOemURL( route ) {
	let oemDomain = VueCookieNext.getCookie( 'oemURL' );
	if ( oemDomain !== route.params.oemDomain ) {
		oemDomain = route.params.oemDomain;
		const flag = await $existOem( oemDomain );
		if ( !flag ) { return; }
		VueCookieNext.setCookie( 'oemURL', oemDomain, { expire: '1y', path: '/' });
	}
}
export { setGlobalCssVar, init, useOemURL };
