<template>
	<div class="privacyPolicy">
		<xl-dialog modalClass="enclosure-overlay" v-model="dialogVisible" :title="$t(title)" width="50%" modal top="0">
			<div class="ppHtml" v-html="privacyHtml" v-loading="getInfoLoading"></div>
		</xl-dialog>
	</div>
</template>

<script setup>
import Login from '@/api/pub';
import { XlDialog } from '@shining3d/ui-components';
import { useLang } from '@/hooks/lang';
import { VueCookieNext } from 'vue-cookie-next';
import { $rdoLang } from '@/assets/common/common.js';
const { $t } = useLang( '@shining3d/app' );
const privacyHtml = ref( '' );
const props = defineProps({
	type: { type: String, default: 'privacy' },
	title: {
		type: String,
		default: 'app-login.privacyPolicy'
	}
});
const cookie = VueCookieNext;
const getInfoLoading = ref( false );
const dialogVisible = ref( false );
const getInfo = async () => {
	getInfoLoading.value = true;
	privacyHtml.value = '';

	await Login.getOigpc( $rdoLang( cookie.getCookie( 'lang' )), VueCookieNext.getCookie( 'oemURL' ), props.type ).
		then(( res ) => {
			console.log( 'getOigpc' + res );
			if ( res.status === 'success' ) {
				privacyHtml.value = res.result;
			}
			getInfoLoading.value = false;
		}).
		catch(() => {
			getInfoLoading.value = false;
		});
};
defineExpose({ dialogVisible, getInfo });
</script>

<style scoped lang="scss">
.privacyPolicy {
	:deep(.el-overlay-dialog) {
		display: flex;
		align-items: center;
		.xl-dialog {
			margin-bottom: 0;
		}
		.ppHtml {
			word-break: normal;
			max-height: 80vh;
			min-height: 100px;
		}
	}
}
</style>
