<template>
	<router-view>
	</router-view>
	<!-- cookies提示-自动触发-屈佳豪 -->
  <xl-cookies v-if="!whiteList.includes(currentRouter)" :bgImg="$imageUrl('cookies.png')" :iconImg="$imageUrl('theTriangle.svg')" @openPrivacyPolicy="openPrivacyPolicy"></xl-cookies>
	<!-- 隐私政策-点击触发-刘杰 -->
	<privacy-policy :type="policyType" :title="policyTitle" ref="termsOfUseRef" ></privacy-policy>
	<!-- 撤销账号注销提示-刘杰 -->
	<cancel-prompt v-if="!whiteList.includes(currentRouter)" ref="cancelPromptRef" />
	<!-- 公共语言设置-杨涛 -->
	<xl-config-provider></xl-config-provider>
</template>
<script setup>
import cancelPrompt from '@/views/components/cancelPrompt.vue';
import privacyPolicy from '@/views/pub/home/privacyPolicy.vue';
import { XlConfigProvider } from '@shining3d/ui-components';
import { XLCookies as XlCookies } from '@shining3d/xl-login';
import { $getOEM, $imageUrl } from '@/assets/common/common.js';
import { VueCookieNext } from 'vue-cookie-next';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import throttle from 'lodash/throttle';
import { getNoParseCountryCode } from '@shining3d/common-module/utils/libphonenumber';
import http from '@/utils/http';
import { whiteList } from '@/router';
import { init, useOemURL } from './init.js';
import { updatedOem } from '@/utils/whiteList';
const route = useRoute();
const $store = useStore();

const currentRouter = ref( location.pathname );
// 语言存储
$store.commit( 'setI18n', window.app.i18n || {});
// ETCD存储
$store.commit( 'setGlobal', window.app.global || {});
const openPrivacyPolicy = () => {
	$store.commit( 'setPrivacyPolicy' );
};

// 刘杰
// 弹窗类型、弹窗标题
const policyType = ref( '' );
const policyTitle = ref( '' );
// 弹窗Ref
const termsOfUseRef = ref();
const termsOfUseChange = () => {
	// 获取内容
	termsOfUseRef.value.getInfo();
	termsOfUseRef.value.dialogVisible = true;
};
// 触发隐私政策弹窗
const tcpChange = ( title, type ) => {
	policyTitle.value = title;
	policyType.value = type;
	nextTick(() => { termsOfUseChange(); });
};
// 隐私政策截流
const pChange = throttle(( title, type ) => {
	tcpChange( title, type );
}, 2000, { leading: true, trailing: false });
// 隐私政策
const privacyPolicySwitch = computed(() => $store.state.privacyPolicySwitch );
watch( privacyPolicySwitch, ( val ) => { pChange( 'app-login.privacyPolicy', 'privacy' ); });
// 使用条款
const termsOfUseSwitch = computed(() => $store.state.termsOfUseSwitch );
watch( termsOfUseSwitch, ( val ) => { pChange( 'app-login.termsOfUse', 'termsOfUse' ); });
// 数据迁移声明
const dataMigrateSwitch = computed(() => $store.state.dataMigrateSwitch );
watch( dataMigrateSwitch, ( val ) => { pChange( 'institutionMore.dataMigrate.notice.statement', 'dataMigrateNotice' ); });
// 机构解散须知
const orgDissolveNoticeSwitch = computed(() => $store.state.orgDissolveNoticeSwitch );
watch( orgDissolveNoticeSwitch, ( val ) => { pChange( 'institutionMore.dissolve.notice.noticeCheck', 'orgDissolveNotice' ); });
// 授权协议
const licenseAgreement = computed(() => $store.state.licenseAgreementSwitch );
watch( licenseAgreement, ( val ) => { pChange( 'feature.dialog.check.license', 'licenseAgreement' ); });


init();

// OEM更新-刘杰
let onceTimes = true;
const cancelPromptRef = ref( null );


// 监听路由改变oem-刘杰
watch( route, ( val ) => {
	if ( onceTimes && !whiteList.includes( currentRouter.value )) {
		onceTimes = false;
		if ( val && val.name && val.name.includes( 'oemDomainPage' )) {
			useOemURL( val );
		} else {
			cancelPromptRef.value?.getCancelInfo();
		}
		// 刷新白名单OEM参数
		updatedOem();
		// 获取OEM配置
		$getOEM();
	}
});


onMounted(() => {
	// 获取国家Code配置-陈家兴
	getNoParseCountryCode( http );
	// 关闭页面删除cc
	window.onbeforeunload = () => {
		VueCookieNext.removeCookie( 'cc' );
	};
});
</script>
<style lang="scss">
@use '@/assets/common/common.scss' as *;
#app {
	width: 100%;
	height: 100%;
	word-break: break-word
}

// .el-table .el-scrollbar__thumb:hover {
// 	opacity: .3;
// }

/* 清除默认的内外边距 /
/ 内减模型:盒子的边框与边距不会影响盒子的大小 */
body,
input,
img,
div,
ul,
li,
a,
span,
i,
p,
h1,
h2,
h3,
h4,
h5,
h6,
strong,
ins,
em,
del {
	margin: 0;
	padding: 0;
}

html,
body {
	height: 100%;
	font-family: $font-family;
	-webkit-font-smoothing: antialiased;
	color: $color-n10;
}

/* 清除a标签的下划线和字体颜色 */
a {
	text-decoration: none;
}

/* 清除li标签前的小点点 */
li {
	list-style: none;
}

/* 清除input标签的边框线和外轮廓线 */
input {
	border: none;
	outline: none;
}

/* 清除img标签在地基浏览器中的自带边框 /
/ 控制文字与图片的中线对齐 */
img {
	border: none;
	vertical-align: middle;
}

/* 调整浏览器的默认字体大小为12px /
/ 设置 容器高度=字体大小 */
body {
	line-height: $line-height;
}
.recommendTip{
	max-width: 280px;
}
</style>
