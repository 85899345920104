<template>
	<div class="cancelPrompt" v-if="cancelShow && cancelDate">
		<div class="cancelPrompt-l">
			<el-image class="cancelPrompt-infoimg" style="width: 24px; height: 24px" :src="$imageUrl('small-avatar.svg')" fit="contain" />
			<div class="cancelPrompt-conts">
				{{ $t('personalInformation.passWord.applicationUndoRemind1') }}<span class="cancelPrompt-ctsDate">{{ cancelDate }}</span
				>{{ $t('personalInformation.passWord.applicationUndoRemind2') }}<span @click="cancelToWithdraw" class="cancelPrompt-ctsBtn"
				>{{ $t('personalInformation.passWord.withdrawCancelApply') }}</span
				>{{ $t('personalInformation.passWord.applicationUndoRemind3') }}
			</div>
		</div>
		<el-image class="cancelPrompt-rImg" @click="close" style="width: 16px; height: 16px" :src="$imageUrl('instRoleSel/close.svg')" fit="contain" />
	</div>
</template>

<script setup>
import { useLang } from '@/hooks/lang';
import { XlMessageBox, XlMessage } from '@shining3d/ui-components';
import $moment from 'moment';
import info from '@/api/info';
import { useStore } from 'vuex';
import { useMatomo } from '@shining3d/matomo';
import { $getLocalStorage, $setLocalStorage, $convertUTCDateToLocalDate, $imageUrl } from '@/assets/common/common.js';
const $store = useStore();
const matomo = useMatomo();

const { $t } = useLang( '@shining3d/app' );
const cancelShow = ref( false );
// const cancelDate = ref( '' );
const close = () => {
	$setLocalStorage( 'cancelPromptFlag', true );
	cancelShow.value = false;
};
const cancelToWithdraw = () => {
	XlMessageBox({
		title: $t( 'personalInformation.passWord.withdrawCancelApply' ),
		message: $t( 'personalInformation.passWord.withdrawCancelApplyPrompt' ),
		confirmButtonText: $t( 'common.confirmToWithdraw' ),
		cancelButtonText: $t( 'button.cancel' ),
		beforeClose: async ( action, instance, done ) => {
			if ( action === 'confirm' ) {
				instance.confirmButtonLoading = true;
				await onCancelRevoke();
				instance.confirmButtonLoading = false;
				done();
			} else {
				done();
			}
		}
	});
};

const getCancelInfo = () => {
	$store.dispatch( 'getCancelInfo', () => {
		const cancelPromptLocal = $getLocalStorage( 'cancelPromptFlag' );
		if ( $store.state.applyCancelOn && !cancelPromptLocal ) {
			cancelShow.value = true;
		}
	});
};

const cancelDate = computed(() => {
	let date = '';
	if ( $store.state.applyCancelOn ) {
		date = $moment( $convertUTCDateToLocalDate( $store.state.applyCancelOn )).format( 'YYYY/MM/DD HH:mm' );
	} else {
		cancelShow.value = false;
	}
	return date;
});

const onCancelRevoke = () => {
	info.cancelRevoke().then(( res ) => {
		if ( res.status === 'success' ){
			XlMessage({ type: 'success', message: $t( 'messages.successRevoke' ) });
			matomo.log( 'event', '提示-撤回注销申请|页面提示｜撤回注销申请｜二次确认撤回注销', '在页面提示-撤回注销申请-二次确认撤回注销' );
		}
		getCancelInfo();
		cancelShow.value = false;
	});
};
defineExpose({ getCancelInfo });
</script>

<style scoped lang="scss">
.cancelPrompt {
	position: fixed;
	top: 84px;
	left: 50%;
	transform: translateX(-50%);
	background: $color-n1;
	box-shadow: 0px 10px 40px 0px rgba(38, 38, 38, 0.18);
	border-radius: 2px;
	border: 1px solid $color-o4;
	display: flex;
	align-items: center;
	width: 930px;
	justify-content: space-between;
	padding: 12px 16px;
	.cancelPrompt-l {
		display: flex;
		align-items: center;
	}
	.cancelPrompt-conts {
		margin-left: 8px;
		font-size: 14px;
		color: $color-n9;
		line-height: 20px;
    padding-right: 16px;
	}
	.cancelPrompt-rImg {
		position: absolute;
		right: 16px;
		top: 16px;
		cursor: pointer;
	}
	.cancelPrompt-ctsDate {
		color: $color-o4;
		margin: 0 4px;
		font-weight: 600;
	}

	.cancelPrompt-ctsBtn {
		color: $color-b4;
		margin-left: 4px;
		text-decoration: underline;
		cursor: pointer;
	}
}
</style>
