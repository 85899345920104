import { XlOverlay } from '@shining3d/ui-components';
import Bus from '@/bus';

let id = 0;
const showdownID = [];

XlOverlay.beforeMount = function () {
	id += 1;
	this.id = id;
};

XlOverlay.beforeUnmount = function() {
	const index = showdownID.indexOf( this.id );
	if ( index > -1 ) {
		showdownID.splice( index, 1 );
		if ( !showdownID.length ) {
			Bus.$emit( 'MAKSOVERALL', false );
		}
	}
};

XlOverlay.updated = function() {
	const display = this.$el?.style?.display;
	const classNames = this.$el?.getAttribute( 'class' );
	if ( !classNames ) { return; }
	if (( classNames?.includes( '-enter-from' ) && classNames?.includes( '-enter-active' )) || ( display !== 'none' && ( !classNames?.includes( '-leave-from' ) || !classNames?.includes( '-leave-active' )))) {
		const index = showdownID.indexOf( this.id );
		if ( index === -1 ) {
			showdownID.push( this.id );
			if ( showdownID.length === 1 ) {
				Bus.$emit( 'MAKSOVERALL', true );
			}
		}
	} else {
		const index = showdownID.indexOf( this.id );
		if ( index > -1 ) {
			showdownID.splice( index, 1 );
			if ( !showdownID.length ) {
				Bus.$emit( 'MAKSOVERALL', false );
			}
		}
	}
};
