class Bus {
	constructor() {
		this.list = {};
		this.onceList = {};
	}

	$on( name, fn ) {
		this.list[ name ] = this.list[ name ] || [];
		this.list[ name ].push( fn );
	}

	$once( name, fn ) {
		this.onceList[ name ] = this.onceList[ name ] || [];
		this.onceList[ name ].push( fn );
	}

	$emit( name, data ) {
		if ( this.list[ name ]) {
			this.list[ name ].forEach(( fn ) => {
				fn( data );
			});
		}
		if ( this.onceList[ name ]) {
			this.onceList[ name ].forEach(( fn ) => {
				fn( data );
			});
			delete this.onceList[ name ];
		}
	}

	$off( name, fn ) {
		if ( fn ) {
			if ( this.list[ name ]) {
				this.list[ name ] = this.list[ name ].filter(( v ) => v !== fn );
				if ( !this.list[ name ].length ) {
					delete this.list[ name ];
				}
			}
			if ( this.onceList[ name ]) {
				this.onceList[ name ] = this.list[ name ].filter(( v ) => v !== fn );
				if ( !this.onceList[ name ].length ) {
					delete this.onceList[ name ];
				}
			}
		} else {
			if ( this.list[ name ]) {
				delete this.list[ name ];
			}
			if ( this.onceList[ name ]) {
				delete this.list[ name ];
			}
		}
	}

	$clearAll() {
		this.list = [];
		this.onceList = {};
	}
}
export default new Bus();
